contact-form {
  display: block;
  background-color: white;
  
  fieldset legend,
  mk-textarea label {
    font-family: 'Asap Condensed', sans-serif;
    font-weight: 500;
  }

  fieldset legend {
    margin: 0 0 1.8rem 0;
  }

  input,
  select,
  textarea {
    font-family: Asap;
    height: 2.2em;
    border-radius: 4px;
    border-color: var(--dark-purple-50);
    border-width: 1px;
    font-size: 1em;
    border-style: solid;
    position: relative;
    box-sizing: border-box;

    &::placeholder {
      color: var(--dark-purple-50);
      font-size: .6em;
      text-align: right;
      line-height: 20px;
    }

    &::-ms-input-placeholder {
      color: var(--dark-purple-50);
      font-size: 11px;
      text-align: right;
      line-height: 20px;
    }

    &:focus {
      border-color: var(--orange);
      outline: auto;
      outline-color: var(--orange);
      box-shadow: none;

      &::placeholder {
        color: var(--dark-purple);
      }
    }
  }

  mk-input {
    display: block;
    position: relative;
    padding: 0 0 1.4em 0;
    font-size: 20px;
  
    datalist {
      background-color: #BF4A1F;
    }

    input {
      width: 100%;
    }
  
    label {
      top: 0;
      position: absolute;
      left: .5rem;
      line-height: 1em;
      font-weight: bold;
      color: var(--dark-purple);
    }
  
    input:placeholder-shown+label {
      position: absolute;
      left: .5rem;
      line-height: 2.2em;
      font-weight: normal;
  
      transition: background-color 0.2s, color 0.2s,
        top 0.2s,
        bottom 0.2s,
        right 0.2s,
        left 0.2s;
    }
  
    input:required+label {
      font-weight: 700;
    }
  
    input:not(:placeholder-shown) {
      border-color: var(--green);
    }
  
    input:focus+label,
    input:not(:placeholder-shown)+label {
      top: -1em;
      left: .5rem;
      line-height: 1em;
      font-weight: bold;
      color: var(--dark-purple);
    }
  
  }

  mk-textarea {
    font-size: 20px;
    position: relative;

    width: 100%;
    min-height: 10rem;
    display: block;
    padding: 2.8rem 0 0 0;

    label {
      font-weight: bold;
      color: var(--dark-purple);
      font-size: 1rem;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      line-height: 1;
    }

    textarea:placeholder-shown+label {
      position: absolute;
      top: 0;
      left: 0;
    }

    textarea:focus+label,
    textarea:not(:placeholder-shown)+label {
      top: 26px;
      left: .5rem;
      font-size: 20px;
    }

    textarea {
      resize: vertical;
      min-height: 6em;
      width: 100%
    }

    textarea::placeholder {
      font-size: 1rem;
      text-align: right;
    }

    textarea:focus::placeholder {
      transform: translateY(0);
      color: transparent;

      transition: transform 0.2s, font-size 0.2s;
      font-size: 20px;

    }

    textarea:placeholder-shown+label {
      font-weight: 400;

      transition: background-color 0.2s, color 0.2s,
        top 0.2s,
        bottom 0.2s,
        right 0.2s,
        left 0.2s;
    }

    textarea:focus+label,
    textarea:not(:placeholder-shown)+label {
      line-height: 1em;
      font-weight: bold;
      color: var(--dark-purple);
      font-size: 1.2rem;
      font-family: Asap, sans-serif;
    }
  }

  textarea {
    padding: .5em;

    resize: vertical;

    &::placeholder {
      font: inherit;
      text-align: left;
    }
  }

  form {

    padding: 1.6rem;

    &:invalid input[type=submit] {
      background-color: var(--orange-50);
      content: "Bitte alle Felder ausfüllen";
    }
  }

  input[type=submit] {
    background-color: var(--orange);
    color: white;
    font-weight: bold;
    margin: 0;
  }
  input.progress[type=submit] {
    background-color: var(--dark-purple);
  }

  input.success[type=submit] {
    background-color: var(--mint);
  }

  input.error[type=submit] {
    background-color: red;
  }



  input {
    padding: .2em .5em;
  }

  @include xl {
    padding: 1.4rem 0 0 0;

    fieldset legend {
      margin: 0;
    }

    fieldset {
      padding: 2em 1.4rem 2em 0;
    }

    mk-input {
      flex-grow: 1;
    
      &.submit {
        margin: 1.4em 0 0 0;
      }
    }

    mk-textarea {
      display: flex;
      flex-grow: 4;
      flex-flow: column;
      width: auto;
      padding: 0;


      label {
        top: .8em;
        left: .5rem;
      }

      textarea:focus+label,
      textarea:not(:placeholder-shown)+label {
        top: 26px;
      }

      textarea {
        margin-top: 2.3em;
        min-height: 190px;
        resize: none;
      }
    }

    form {
      display: flex;
      flex-flow: column wrap;
      max-height: 342px;
      padding: 1em 15vw;  
    }
  }
}