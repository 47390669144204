/* asap-condensed-200 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-300 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-regular - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-500 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-600 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-700 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-800 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-condensed-900 - latin */
@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('../fonts/asap-condensed-v16-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-condensed-v16-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* asap-100 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('../fonts/asap-v26-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-200 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../fonts/asap-v26-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-300 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/asap-v26-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-regular - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/asap-v26-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-500 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/asap-v26-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-600 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/asap-v26-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-700 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/asap-v26-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-800 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/asap-v26-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-900 - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('../fonts/asap-v26-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-100italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 100;
  src: local(''),
       url('../fonts/asap-v26-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-200italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 200;
  src: local(''),
       url('../fonts/asap-v26-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-300italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('../fonts/asap-v26-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/asap-v26-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-500italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('../fonts/asap-v26-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-600italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 600;
  src: local(''),
       url('../fonts/asap-v26-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-700italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('../fonts/asap-v26-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-800italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 800;
  src: local(''),
       url('../fonts/asap-v26-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-900italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-weight: 900;
  src: local(''),
       url('../fonts/asap-v26-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/asap-v26-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}