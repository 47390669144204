html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("asap-condensed-v16-latin-200.63360909.woff2") format("woff2"), url("asap-condensed-v16-latin-200.d815f862.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("asap-condensed-v16-latin-300.2bcc3c3a.woff2") format("woff2"), url("asap-condensed-v16-latin-300.3921264e.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("asap-condensed-v16-latin-regular.9df79394.woff2") format("woff2"), url("asap-condensed-v16-latin-regular.92206052.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("asap-condensed-v16-latin-500.a8735ce4.woff2") format("woff2"), url("asap-condensed-v16-latin-500.8c1bb68c.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("asap-condensed-v16-latin-600.0280c518.woff2") format("woff2"), url("asap-condensed-v16-latin-600.5e758fe3.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("asap-condensed-v16-latin-700.1de6109c.woff2") format("woff2"), url("asap-condensed-v16-latin-700.092bab96.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("asap-condensed-v16-latin-800.c126c8a4.woff2") format("woff2"), url("asap-condensed-v16-latin-800.b5e6f29b.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("asap-condensed-v16-latin-900.a865b613.woff2") format("woff2"), url("asap-condensed-v16-latin-900.d9761ee8.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("asap-v26-latin-100.df4fcfc2.woff2") format("woff2"), url("asap-v26-latin-100.298fb9ff.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("asap-v26-latin-200.7476ee3e.woff2") format("woff2"), url("asap-v26-latin-200.9fd41973.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("asap-v26-latin-300.08e0eeea.woff2") format("woff2"), url("asap-v26-latin-300.8cf5302b.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("asap-v26-latin-regular.e51cdcfc.woff2") format("woff2"), url("asap-v26-latin-regular.89ba5238.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("asap-v26-latin-500.8d077c2a.woff2") format("woff2"), url("asap-v26-latin-500.e96f914c.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("asap-v26-latin-600.5007eeea.woff2") format("woff2"), url("asap-v26-latin-600.a4d71e14.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("asap-v26-latin-700.e4b96bcc.woff2") format("woff2"), url("asap-v26-latin-700.a2d6c944.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("asap-v26-latin-800.bf6ab72e.woff2") format("woff2"), url("asap-v26-latin-800.76759d9f.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("asap-v26-latin-900.2cc75b39.woff2") format("woff2"), url("asap-v26-latin-900.aac2459d.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 100;
  src: local(""), url("asap-v26-latin-100italic.f77c8adb.woff2") format("woff2"), url("asap-v26-latin-100italic.935984db.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 200;
  src: local(""), url("asap-v26-latin-200italic.d8702aea.woff2") format("woff2"), url("asap-v26-latin-200italic.7a0fc746.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("asap-v26-latin-300italic.a438876a.woff2") format("woff2"), url("asap-v26-latin-300italic.ae231c08.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("asap-v26-latin-italic.e22ec42b.woff2") format("woff2"), url("asap-v26-latin-italic.2d79d37c.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 500;
  src: local(""), url("asap-v26-latin-500italic.e9fc62f2.woff2") format("woff2"), url("asap-v26-latin-500italic.7d4e5db0.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("asap-v26-latin-600italic.e0af2fb9.woff2") format("woff2"), url("asap-v26-latin-600italic.4bdfe410.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("asap-v26-latin-700italic.af388bf8.woff2") format("woff2"), url("asap-v26-latin-700italic.3daf990a.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 800;
  src: local(""), url("asap-v26-latin-800italic.bfde417e.woff2") format("woff2"), url("asap-v26-latin-800italic.e921ab3b.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 900;
  src: local(""), url("asap-v26-latin-900italic.bc9026ef.woff2") format("woff2"), url("asap-v26-latin-900italic.ee6295c3.woff") format("woff");
}

.full-size, .flip-box .back, .flip-box .front, .flip-box .object {
  width: 100%;
  height: 100%;
}

.slice, .flip-box .flank, .flip-box .back, .flip-box .front {
  display: block;
  position: absolute;
  overflow: hidden;
}

.flip-box {
  --box-height: 67px;
  --perspective: 50px;
  --divided-perspective: calc(var(--perspective) / 2);
  float: left;
  z-index: 2;
  height: var(--box-height);
  perspective: 400px;
  display: block;
  position: relative;
}

@media (min-width: 1200px) {
  .flip-box {
    --box-height: 100px;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateZ(-var(--divided-perspective) ) rotateX(0deg);
  }

  50% {
    transform: translateZ(-var(--divided-perspective) ) rotateX(180deg);
  }

  100% {
    transform: translateZ(-var(--divided-perspective) ) rotateX(0deg);
  }
}

.flip-box .object {
  transform-style: preserve-3d;
  transform: translateZ(calc(0px - var(--divided-perspective) ) );
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  position: absolute;
}

.flip-box .object.cookie-bar {
  transform: translateZ(calc(0px - var(--divided-perspective) ) ) rotateY(180deg);
  transition-property: transform;
  transition-duration: 2.5s;
  transition-timing-function: ease-in-out;
}

.flip-box .front {
  z-index: 1;
  transform: rotateY(0deg) translateZ(var(--divided-perspective) );
  box-sizing: border-box;
  background-color: #410b66;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

@media (min-width: 1200px) {
  .flip-box .front {
    padding: 0 40px;
    display: block;
  }
}

.flip-box .back {
  z-index: 0;
  transform: rotateY(180deg) translateZ(var(--divided-perspective) );
  background-color: #fd722b;
}

.flip-box .flank {
  width: 50px;
  height: var(--box-height);
  color: #410b66;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 250px;
  transform: rotateY(-90deg)translateZ(50vw);
}

.flip-box.flip-up .object.cookie-bar {
  transform: translateZ(calc(0px - var(--divided-perspective) ) ) rotateX(180.5deg);
}

.flip-box.flip-up .back {
  transform: rotateX(180deg) translateZ(var(--divided-perspective) );
}

.flip-box.flip-up .flank {
  width: 100%;
  height: 50px;
  left: 0;
  top: calc((var(--box-height)  - var(--perspective) ) / 2);
  transform: rotateX(-89deg) translateZ(calc(var(--box-height) / 2) );
}

.flip-box.alternative .front {
  background-color: #931e83;
}

.flip-box.alternative .back {
  background-color: #bd27a9;
}

.flip-box.alternative .flank {
  background-color: #69155d;
}

.no-csstransforms3d .flip-box .object {
  transform-style: none;
  position: relative;
  overflow: hidden;
}

.no-csstransforms3d .flip-box .object .back {
  z-index: 0;
  transition-property: top;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  top: 100%;
}

.no-csstransforms3d .flip-box .object:hover .back {
  z-index: 1;
  top: 0;
}

.no-csstransforms3d .flip-box .flank {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .object {
    position: relative;
    overflow: hidden;
    transform: none !important;
  }

  .object .back {
    z-index: 0;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    top: 100%;
  }

  .object:hover .back {
    z-index: 1;
    top: 0;
    transform: rotateY(0deg)translateZ(0);
  }
}

:root {
  --dark-dark-purple: #270042;
  --dark-purple: #410b66;
  --dark-purple-50: #410b6680;
  --orange: #bf4a1f;
  --orange-50: #bf4a1f80;
  --beige: #fffbf8;
  --light-green: #658f9d;
  --green: #0a4460;
  --font: "Asap", Helvetica, Verdana, sans-serif;
  --text-color: black;
  --light-purple: #8d4cb9;
  --mint: #4c90a0;
  --mint-dark: #046f88;
  font-size: 16px;
}

.nottrueanymore {
  text-decoration: line-through;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(0, -1px, 0);
  }

  20%, 80% {
    transform: translate3d(0, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -4px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 4px, 0);
  }
}

#cookiebar {
  background: var(--orange);
  color: var(--dark-purple);
  position: absolute;
  top: 0;
}

hr {
  display: none;
}

b {
  font-weight: bold;
}

a:link, a:visited {
  color: var(--orange);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #270042;
  background: var(--beige);
  grid-template-columns: 12px auto 12px;
  grid-template-areas: "header header header"
                       "stage stage stage"
                       "quote quote quote"
                       ". leistungen ."
                       "quote3 quote3 quote3"
                       ". methoden ."
                       "meinung meinung meinung"
                       "kontakt kontakt kontakt"
                       "footer footer footer";
  margin: 0;
  font-family: Asap, sans-serif;
  display: grid;
}

body > div:nth-of-type(1) {
  background: var(--dark-purple);
  grid-area: headerright;
}

body > div:nth-of-type(2) {
  background: #270042;
  grid-area: footerleft;
}

@media screen and (min-width: 1200px) {
  body {
    grid-template-columns: 40px 50px 70fr 50px 40px auto;
    grid-template-areas: "header header header header header"
                         ". stage stage stage ."
                         "quote quote quote quote quote"
                         ". leistungen leistungen leistungen ."
                         "quote3 quote3 quote3 quote3 quote3"
                         ". . methoden . ."
                         "meinung meinung meinung meinung meinung"
                         "quote2 quote2 quote2 quote2 quote2"
                         ". kontakt kontakt kontakt ."
                         "footer footer footer footer footer";
  }
}

body > p:first-of-type {
  background: #270042;
  grid-area: quote;
}

body > p:first-of-type span {
  display: none;
}

@media (min-width: 1200px) {
  body > p:first-of-type {
    margin: 0;
  }

  body > p:first-of-type span {
    display: inline;
  }
}

body > p:first-of-type a:link, body > p:first-of-type a:visited {
  background-color: var(--orange);
  color: #fff;
}

body > p:nth-of-type(2) {
  background: #013e4c;
  grid-area: quote3;
}

@media (min-width: 1200px) {
  body > p:nth-of-type(2) {
    background: #270042;
    margin: 0;
  }
}

body > p:last-of-type {
  background: #013e4c;
  grid-area: quote2;
  margin-top: 5rem;
  display: none;
}

@media (min-width: 1200px) {
  body > p:last-of-type {
    display: block;
  }
}

body > p {
  color: #fff;
  text-align: center;
  padding: 1em;
  font-size: .9rem;
  display: block;
}

@media (min-width: 1200px) {
  body > p {
    font-size: 1.1rem;
  }
}

body > header {
  color: #fff;
  grid-area: header;
  font-size: 18px;
}

body > header a:link, body > header a:visited {
  color: #fff;
  text-decoration: none;
}

body > header a:hover {
  color: var(--orange);
}

body > header .flip-content {
  width: 100%;
  align-items: center;
  gap: 10px;
  display: flex;
}

body > header .flip-content.cookiebar {
  color: var(--dark-dark-purple);
  width: auto;
  height: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  padding: .5rem;
  display: flex;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar {
    flex-wrap: initial;
    padding: 0 1rem;
  }
}

body > header .flip-content.cookiebar img {
  display: none;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar img {
    display: unset;
  }
}

body > header .flip-content.cookiebar h2 {
  font-size: 24px;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar h2 {
    font-size: 39px;
  }
}

body > header .flip-content.cookiebar p {
  display: none;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar p {
    display: unset;
    font-size: 24px;
  }
}

body > header .flip-content.cookiebar h2, body > header .flip-content.cookiebar p {
  margin: 0;
}

body > header .flip-content.cookiebar header {
  flex-grow: 2;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar header {
    width: unset;
  }
}

body > header .flip-content.cookiebar button {
  width: 48px;
  height: 33px;
  color: #fff;
  background: none;
  border: 3px solid #fff;
  border-radius: 12px;
  padding: 0;
  font-size: 15px;
}

@media (min-width: 1200px) {
  body > header .flip-content.cookiebar button {
    width: 60px;
    height: 48px;
    margin-right: 1rem;
    font-size: 18px;
  }
}

body > header .flip-content.cookiebar button:focus, body > header .flip-content.cookiebar button:active {
  background: var(--dark-purple);
  outline: none;
}

body > header .flip-content.cookiebar button:active {
  transform: scale(.9);
  box-shadow: inset 3px 3px 3px 3px #000;
}

body > header .flip-content.cookiebar button:hover {
  border-color: var(--dark-purple);
  cursor: pointer;
}

body > header .flip-content.cookiebar button[name="cookies-ok"] {
  color: var(--dark-purple);
  background: #fff;
}

body > header .flip-content.cookiebar button[name="cookies-ok"]:focus, body > header .flip-content.cookiebar button[name="cookies-ok"]:active {
  background: var(--dark-purple);
  color: #fff;
  outline: none;
}

body > header .flip-content.cookiebar button[name="cookies-ok"]:active {
  transform: scale(.9);
  box-shadow: inset 3px 3px 3px 3px #000;
}

@media (min-width: 1200px) {
  body > header .flip-content {
    height: 100px;
    grid-template-columns: 40px auto 400px;
    grid-template-areas: ". logo claim";
    align-items: center;
    font-size: 26px;
    display: grid;
  }
}

body > header .flank-content {
  text-transform: uppercase;
  letter-spacing: 3vw;
  font-size: 28px;
  font-weight: bold;
}

body > header .cookie-icon {
  width: 47px;
  height: 47px;
  margin-bottom: .5rem;
  margin-right: 1rem;
}

@media (min-width: 1200px) {
  body > header .cookie-icon {
    width: 80px;
    height: 80px;
  }
}

body > header img {
  grid-area: logo;
}

body > header .front p {
  text-align: right;
  grid-area: claim;
  margin-left: auto;
  font-size: .75rem;
}

@media (min-width: 1200px) {
  body > header .front p {
    font-size: 26px;
  }
}

body > footer {
  color: var(--beige);
  background: #270042;
  grid-area: footer;
  padding: 2rem 12px;
}

body > footer h2 {
  display: none;
}

body > footer nav {
  text-align: right;
}

@media (min-width: 1200px) {
  body > footer nav {
    margin-top: 3rem;
  }
}

body > footer ul {
  list-style-type: none;
  display: inline-flex;
}

body > footer li {
  margin-left: 1rem;
}

body > footer a:link, body > footer a:visited {
  color: var(--beige);
}

body > footer svg {
  display: none;
}

@media (min-width: 1200px) {
  body > footer svg {
    display: block;
  }
}

.mklogo {
  fill: #fff;
  width: 141px;
  height: 25px;
  margin-top: -8px;
}

@media (min-width: 1200px) {
  .mklogo {
    width: 252px;
    height: 76px;
    margin-top: -9px;
  }
}

#leistungen {
  grid-area: leistungen;
  margin-bottom: 5rem;
}

#leistungen > div {
  flex-direction: column;
  gap: 1.8rem;
  display: flex;
}

#leistungen h2 {
  text-align: center;
  margin: 5rem 0 1em;
  font-size: 40px;
  font-weight: normal;
}

#leistungen section {
  background-color: var(--dark-purple);
  border-radius: 6px;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
  position: relative;
}

#leistungen section:hover ul a:link {
  color: var(--beige);
}

#leistungen section:hover ul a:visited {
  color: #e2d6ca;
}

#leistungen section:hover ul a:hover {
  color: #fff;
}

#leistungen section ul {
  color: #d1bed6;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
  list-style: none;
}

#leistungen section ul li {
  margin-bottom: .3em;
  font-weight: 400;
}

#leistungen section a:not(.main-link) {
  z-index: 1;
  position: relative;
}

#leistungen section .main-link:before {
  content: "";
  position: absolute;
  inset: 0;
}

#leistungen section a:link {
  color: #d1bed6;
}

#leistungen section a:visited {
  color: #af86bb;
  opacity: .8;
}

#leistungen section a:hover, #leistungen section a:hover h3 {
  color: #fff;
}

#leistungen section h3, #leistungen section > a {
  color: var(--beige);
  align-self: end;
  margin: 1.4rem 0 0;
  font-size: 42px;
  font-weight: normal;
  display: block;
}

@media (min-width: 1200px) {
  #leistungen > div {
    flex-direction: row;
    gap: 6%;
  }

  #leistungen section {
    width: 27%;
    padding: 19px;
    font-size: 60px;
  }

  #leistungen section ul {
    font-size: 1.3rem;
  }
}

#projekte {
  flex-direction: column;
  grid-area: methoden;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  display: flex;
}

#projekte:target {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
}

#projekte h2 {
  text-align: center;
  margin: 5rem 0 1em;
  font-size: 40px;
  font-weight: normal;
}

#projekte section {
  min-width: 50vw;
  max-width: 1000px;
  background: #fff;
  margin-bottom: 20px;
  transition-property: transform, box-shadow;
  transition-duration: .5s;
  display: flex;
  box-shadow: 0 0 1px #7b2a00;
}

#projekte section:hover {
  box-shadow: 0px 12px 12px -12px var(--orange);
  transform: translate(0, -12px);
}

#projekte section header {
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 1em;
  display: flex;
}

#projekte section header h3 {
  margin: .2em 0;
  margin-block: .2em;
  font-size: 30px;
  font-weight: normal;
}

@media (min-width: 1200px) {
  #projekte section header h3 {
    font-size: 36px;
  }
}

#projekte section header p {
  color: var(--mint-dark);
  margin: 0;
  padding: 0;
  font-weight: bold;
}

#projekte section footer {
  text-align: right;
  align-self: flex-end;
  padding: 1em;
  display: block;
}

#meinung {
  color: #fff;
  background-color: #4c90a0;
  flex-direction: column;
  grid-area: meinung;
  justify-content: center;
  align-items: center;
  padding: 5rem 2rem;
  display: flex;
}

#meinung h2 {
  text-align: center;
  margin: 0 0 1.8em;
  font-size: 40px;
  font-weight: normal;
}

#meinung blockquote {
  max-width: 75vw;
  font-style: italic;
  line-height: 1.6em;
}

#meinung blockquote footer {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
}

@media (min-width: 1200px) {
  #meinung {
    padding: 5rem;
  }

  #meinung blockquote {
    max-width: 50vw;
  }
}

#kontakt {
  grid-area: kontakt;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#kontakt:target {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
}

#kontakt h2 {
  text-align: center;
  margin: 0 0 1em;
  font-size: 40px;
  font-weight: normal;
}

#kontakt section {
  background: #fff;
  margin-bottom: 20px;
  transition-property: transform, box-shadow;
  transition-duration: .5s;
  display: flex;
  box-shadow: 0 0 1px #7b2a00;
}

#kontakt section:hover {
  box-shadow: 0px 12px 12px -12px var(--orange);
  cursor: pointer;
  transform: translate(0, -12px);
}

#kontakt section header {
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 1em;
  display: flex;
}

#kontakt section header h3 {
  margin: .2em 0;
  margin-block: .2em;
  font-size: 30px;
  font-weight: normal;
}

@media (min-width: 1200px) {
  #kontakt section header h3 {
    font-size: 36px;
  }
}

#kontakt section header p {
  color: #470674;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

#kontakt section footer {
  text-align: right;
  align-self: flex-end;
  padding: 1em;
  display: block;
}

dialog {
  padding: 0;
}

dialog .dialog-content {
  margin: 1rem;
}

dialog h2 {
  margin-top: 0;
}

dialog h2 span {
  color: var(--green);
}

dialog input[type="email"] {
  width: 30vw;
}

#stage {
  grid-template: "header header" 1fr
                 "button photo" 1fr
                 ". photo"
                 / 1fr 1fr;
  grid-area: stage;
  margin-top: 24px;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1200px) {
  #stage {
    grid-gap: 26px;
    grid-template-rows: 1fr 2fr 2fr 10px;
    grid-template-columns: 6fr 1fr 188px;
  }

  #stage ul {
    justify-content: space-evenly;
    padding: 0;
    list-style: none;
    display: flex;
  }
}

#stage > section {
  background: #fff;
  grid-area: 1 / 1 / span 3 / span 5;
  padding: 0 12px;
}

@media (min-width: 1200px) {
  #stage > section {
    grid-area: 1 / 1 / span 3 / span 2;
    padding: 3rem 6rem;
  }
}

#stage > section a {
  border: 3px solid var(--orange);
  width: 122px;
  text-align: center;
  border-radius: 18px;
  padding: .8em;
  display: block;
}

@media (min-width: 1200px) {
  #stage > section a {
    width: 180px;
  }
}

#stage > section a:link, #stage > section a:visited {
  color: var(--orange);
  text-decoration: none;
  transition-property: transform, box-shadow;
  transition-duration: .5s;
}

#stage > section a:hover {
  background: var(--orange);
  color: #fff;
  border-color: var(--dark-purple);
  box-shadow: 0px 3px 3px -3px var(--orange);
  transform: translate(0, -3px);
}

#stage > section a:link.coaching, #stage > section a:visited.coaching {
  background: var(--orange);
  color: #fff;
}

#stage #photo {
  grid-area: 3 / 4 / span 2;
  align-self: self-end;
}

@media (min-width: 1200px) {
  #stage #photo {
    grid-area: 2 / 2 / span 3 / span 2;
    align-self: end;
  }
}

#stage p {
  color: var(--dark-dark-purple);
  margin: 0;
  font-size: 14px;
}

@media (min-width: 1200px) {
  #stage p {
    font-size: 21px;
  }
}

#stage ul {
  color: var(--dark-dark-purple);
  flex-direction: column;
  margin-block-start: 4rem;
  padding: 0;
  font-size: 24px;
  line-height: 1.2;
  list-style: none;
  display: none;
}

@media (min-width: 1200px) {
  #stage ul {
    justify-content: center;
    padding: 0;
    display: flex;
  }
}

#stage li {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  #stage li {
    margin: 0 1rem .25em 0;
  }
}

#stage nav {
  margin-block-start: 4rem;
  font-size: 14px;
}

#stage nav span {
  display: none;
}

@media (min-width: 1200px) {
  #stage nav span {
    display: inline;
  }
}

#stage nav ul {
  color: var(--green);
  flex-direction: column;
  margin-block-start: 6rem;
  padding: 0;
  font-size: 21px;
  list-style: none;
  display: flex;
}

@media (min-width: 1200px) {
  #stage nav ul {
    flex-direction: row;
    justify-content: start;
    padding: 0;
  }
}

#stage nav li {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  #stage nav li {
    margin: 0 1rem 0 0;
  }
}

@media (min-width: 1200px) {
  #stage nav {
    font-size: 18px;
  }
}

#stage img {
  width: 171px;
  height: 151px;
  display: block;
}

@media (min-width: 1200px) {
  #stage img {
    width: 318px;
    height: 281px;
  }
}

h1 {
  color: var(--dark-dark-purple);
  margin: 0;
  font-size: 32px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  h1 {
    font-size: 78px;
  }
}

#contact-info {
  color: #fff;
  background: #270042;
  grid-area: 4 / 1 / auto / 5 colspan;
  align-items: center;
  padding: 0 12px;
  display: flex;
  position: relative;
}

#contact-info .name, #contact-info .address {
  display: none;
}

@media (min-width: 1200px) {
  #contact-info {
    color: var(--dark-purple);
    display: initial;
    background: none;
    grid-area: 2 / 3;
  }

  #contact-info .name, #contact-info .address {
    display: block;
  }
}

#contact-info .name {
  font-size: 22px;
  font-weight: bold;
}

#contact-info .title {
  margin: 0;
  display: block;
}

#contact-info .title span {
  display: block;
}

@media (min-width: 1200px) {
  #contact-info .title {
    margin: 1em 0;
  }
}

#contact-info .freelance {
  color: #5b0993;
  margin: .7em 0 .4em;
  font-weight: bold;
  position: absolute;
  top: -33px;
  left: 12px;
}

@media (min-width: 1200px) {
  #contact-info .freelance {
    position: initial;
    top: initial;
    left: initial;
  }
}

#contact-info .tel {
  width: 100%;
  text-align: right;
  display: block;
}

#contact-info .tel a:link {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  #contact-info .tel {
    width: initial;
    text-align: initial;
    margin-top: 1em;
  }

  #contact-info .tel a:link {
    color: var(--orange);
    font-size: 1rem;
  }
}

@media screen {
  .kindle {
    display: none;
  }
}

@media amzn-mobi, amzn-kf8 and (device-height: 1024px) and (device-width: 758px), amzn-kf8 and (device-height: 758px) and (device-width: 1024px), amzn-kf8 and (device-height: 800px) and (device-width: 600px), amzn-kf8 and (device-height: 600px) and (device-width: 800px), amzn-kf8 and (device-height: 1448px) and (device-width: 1072px), amzn-kf8 and (device-height: 1072px) and (device-width: 1448px) {
  .kindle {
    display: block;
  }
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("asap-condensed-v16-latin-200.63360909.woff2") format("woff2"), url("asap-condensed-v16-latin-200.d815f862.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("asap-condensed-v16-latin-300.2bcc3c3a.woff2") format("woff2"), url("asap-condensed-v16-latin-300.3921264e.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("asap-condensed-v16-latin-regular.9df79394.woff2") format("woff2"), url("asap-condensed-v16-latin-regular.92206052.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("asap-condensed-v16-latin-500.a8735ce4.woff2") format("woff2"), url("asap-condensed-v16-latin-500.8c1bb68c.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("asap-condensed-v16-latin-600.0280c518.woff2") format("woff2"), url("asap-condensed-v16-latin-600.5e758fe3.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("asap-condensed-v16-latin-700.1de6109c.woff2") format("woff2"), url("asap-condensed-v16-latin-700.092bab96.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("asap-condensed-v16-latin-800.c126c8a4.woff2") format("woff2"), url("asap-condensed-v16-latin-800.b5e6f29b.woff") format("woff");
}

@font-face {
  font-family: Asap Condensed;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("asap-condensed-v16-latin-900.a865b613.woff2") format("woff2"), url("asap-condensed-v16-latin-900.d9761ee8.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("asap-v26-latin-100.df4fcfc2.woff2") format("woff2"), url("asap-v26-latin-100.298fb9ff.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("asap-v26-latin-200.7476ee3e.woff2") format("woff2"), url("asap-v26-latin-200.9fd41973.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("asap-v26-latin-300.08e0eeea.woff2") format("woff2"), url("asap-v26-latin-300.8cf5302b.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("asap-v26-latin-regular.e51cdcfc.woff2") format("woff2"), url("asap-v26-latin-regular.89ba5238.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("asap-v26-latin-500.8d077c2a.woff2") format("woff2"), url("asap-v26-latin-500.e96f914c.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("asap-v26-latin-600.5007eeea.woff2") format("woff2"), url("asap-v26-latin-600.a4d71e14.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("asap-v26-latin-700.e4b96bcc.woff2") format("woff2"), url("asap-v26-latin-700.a2d6c944.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("asap-v26-latin-800.bf6ab72e.woff2") format("woff2"), url("asap-v26-latin-800.76759d9f.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("asap-v26-latin-900.2cc75b39.woff2") format("woff2"), url("asap-v26-latin-900.aac2459d.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 100;
  src: local(""), url("asap-v26-latin-100italic.f77c8adb.woff2") format("woff2"), url("asap-v26-latin-100italic.935984db.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 200;
  src: local(""), url("asap-v26-latin-200italic.d8702aea.woff2") format("woff2"), url("asap-v26-latin-200italic.7a0fc746.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("asap-v26-latin-300italic.a438876a.woff2") format("woff2"), url("asap-v26-latin-300italic.ae231c08.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("asap-v26-latin-italic.e22ec42b.woff2") format("woff2"), url("asap-v26-latin-italic.2d79d37c.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 500;
  src: local(""), url("asap-v26-latin-500italic.e9fc62f2.woff2") format("woff2"), url("asap-v26-latin-500italic.7d4e5db0.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("asap-v26-latin-600italic.e0af2fb9.woff2") format("woff2"), url("asap-v26-latin-600italic.4bdfe410.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("asap-v26-latin-700italic.af388bf8.woff2") format("woff2"), url("asap-v26-latin-700italic.3daf990a.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 800;
  src: local(""), url("asap-v26-latin-800italic.bfde417e.woff2") format("woff2"), url("asap-v26-latin-800italic.e921ab3b.woff") format("woff");
}

@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 900;
  src: local(""), url("asap-v26-latin-900italic.bc9026ef.woff2") format("woff2"), url("asap-v26-latin-900italic.ee6295c3.woff") format("woff");
}

.Competences__bigpicture text {
  fill: #d1bed6;
  opacity: 1;
  stroke: none;
  text-anchor: start;
  opacity: 1;
  stroke: none;
  font-family: Asap;
  line-height: normal;
}

.Competences__bigpicture text.l {
  font-size: 45.2193px;
}

.Competences__bigpicture text.m {
  font-size: 33.9145px;
}

.Competences__bigpicture text.s {
  font-size: 22.6097px;
}

.Competences__bigpicture text.xs {
  font-size: 11.3048px;
}

.Competences__bigpicture a:hover {
  text-decoration: none;
}

.Competences__bigpicture a:hover text {
  fill: var(--orange);
  font-weight: bold;
}

contact-form {
  background-color: #fff;
  display: block;
}

contact-form fieldset legend, contact-form mk-textarea label {
  font-family: Asap Condensed, sans-serif;
  font-weight: 500;
}

contact-form fieldset legend {
  margin: 0 0 1.8rem;
}

contact-form input, contact-form select, contact-form textarea {
  height: 2.2em;
  border-color: var(--dark-purple-50);
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-family: Asap;
  font-size: 1em;
  position: relative;
}

contact-form input::placeholder, contact-form select::placeholder, contact-form textarea::placeholder {
  color: var(--dark-purple-50);
  text-align: right;
  font-size: .6em;
  line-height: 20px;
}

contact-form input::-moz-placeholder, contact-form select::-moz-placeholder, contact-form textarea::-moz-placeholder {
  color: var(--dark-purple-50);
  text-align: right;
  font-size: 11px;
  line-height: 20px;
}

contact-form input:focus, contact-form select:focus, contact-form textarea:focus {
  border-color: var(--orange);
  outline: auto;
  outline-color: var(--orange);
  box-shadow: none;
}

contact-form input:focus::placeholder, contact-form select:focus::placeholder, contact-form textarea:focus::placeholder {
  color: var(--dark-purple);
}

contact-form mk-input {
  padding: 0 0 1.4em;
  font-size: 20px;
  display: block;
  position: relative;
}

contact-form mk-input datalist {
  background-color: #bf4a1f;
}

contact-form mk-input input {
  width: 100%;
}

contact-form mk-input label {
  color: var(--dark-purple);
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 0;
  left: .5rem;
}

contact-form mk-input input:placeholder-shown + label {
  font-weight: normal;
  line-height: 2.2em;
  transition: background-color .2s, color .2s, top .2s, bottom .2s, right .2s, left .2s;
  position: absolute;
  left: .5rem;
}

contact-form mk-input input:required + label {
  font-weight: 700;
}

contact-form mk-input input:not(:placeholder-shown) {
  border-color: var(--green);
}

contact-form mk-input input:focus + label, contact-form mk-input input:not(:placeholder-shown) + label {
  color: var(--dark-purple);
  font-weight: bold;
  line-height: 1em;
  top: -1em;
  left: .5rem;
}

contact-form mk-textarea {
  width: 100%;
  min-height: 10rem;
  padding: 2.8rem 0 0;
  font-size: 20px;
  display: block;
  position: relative;
}

contact-form mk-textarea label {
  color: var(--dark-purple);
  z-index: 1;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
}

contact-form mk-textarea textarea:placeholder-shown + label {
  position: absolute;
  top: 0;
  left: 0;
}

contact-form mk-textarea textarea:focus + label, contact-form mk-textarea textarea:not(:placeholder-shown) + label {
  font-size: 20px;
  top: 26px;
  left: .5rem;
}

contact-form mk-textarea textarea {
  resize: vertical;
  min-height: 6em;
  width: 100%;
}

contact-form mk-textarea textarea::placeholder {
  text-align: right;
  font-size: 1rem;
}

contact-form mk-textarea textarea:focus::placeholder {
  color: #0000;
  font-size: 20px;
  transition: transform .2s, font-size .2s;
  transform: translateY(0);
}

contact-form mk-textarea textarea:placeholder-shown + label {
  font-weight: 400;
  transition: background-color .2s, color .2s, top .2s, bottom .2s, right .2s, left .2s;
}

contact-form mk-textarea textarea:focus + label, contact-form mk-textarea textarea:not(:placeholder-shown) + label {
  color: var(--dark-purple);
  font-family: Asap, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1em;
}

contact-form textarea {
  resize: vertical;
  padding: .5em;
}

contact-form textarea::placeholder {
  font: inherit;
  text-align: left;
}

contact-form form {
  padding: 1.6rem;
}

contact-form form:invalid input[type="submit"] {
  background-color: var(--orange-50);
  content: "Bitte alle Felder ausfüllen";
}

contact-form input[type="submit"] {
  background-color: var(--orange);
  color: #fff;
  margin: 0;
  font-weight: bold;
}

contact-form input.progress[type="submit"] {
  background-color: var(--dark-purple);
}

contact-form input.success[type="submit"] {
  background-color: var(--mint);
}

contact-form input.error[type="submit"] {
  background-color: red;
}

contact-form input {
  padding: .2em .5em;
}

@media (min-width: 1200px) {
  contact-form {
    padding: 1.4rem 0 0;
  }

  contact-form fieldset legend {
    margin: 0;
  }

  contact-form fieldset {
    padding: 2em 1.4rem 2em 0;
  }

  contact-form mk-input {
    flex-grow: 1;
  }

  contact-form mk-input.submit {
    margin: 1.4em 0 0;
  }

  contact-form mk-textarea {
    width: auto;
    flex-flow: column;
    flex-grow: 4;
    padding: 0;
    display: flex;
  }

  contact-form mk-textarea label {
    top: .8em;
    left: .5rem;
  }

  contact-form mk-textarea textarea:focus + label, contact-form mk-textarea textarea:not(:placeholder-shown) + label {
    top: 26px;
  }

  contact-form mk-textarea textarea {
    min-height: 190px;
    resize: none;
    margin-top: 2.3em;
  }

  contact-form form {
    max-height: 342px;
    flex-flow: column wrap;
    padding: 1em 15vw;
    display: flex;
  }
}

/*# sourceMappingURL=index.b1ca06e9.css.map */
