@import "variables";
@import "fonts";
.Competences__bigpicture text {
  fill: #d1bed6;
  opacity: 1;
  stroke: none;
  line-height: normal;
  font-family: Asap;
  text-anchor: start;
  opacity: 1;
  stroke: none;
}

.Competences__bigpicture text.l {
  font-size: 45.21932983px;
}

.Competences__bigpicture text.m {
  font-size: 33.91449738px;
}

.Competences__bigpicture text.s {
  font-size: 22.60966492px;
}

.Competences__bigpicture text.xs {
  font-size: 11.30483246px;
}

.Competences__bigpicture {
  a:hover {
    text {
      fill: var(--orange);
      font-weight: bold;
    }
    text-decoration: none;
  }
}

