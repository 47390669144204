@use 'fonts';

@import "../..//node_modules/reset-css/reset.css";

@import "responsive";
@import "flipbox";
@import "variables";

:root {
  --dark-dark-purple: #270042;
  --dark-purple: #410B66;
  --dark-purple-50: rgba(65, 11, 102, 0.5);
  --orange: #BF4A1F;
  --orange-50: rgba(191,74,31, 0.5);
  --beige: #fffbf8;
  --light-green: rgb(101, 143, 157);
  --green: #0A4460;
  --font: 'Asap', Helvetica, Verdana, sans-serif;
  --text-color: black;
  --light-purple: #8D4CB9;
  --mint: #4c90a0;
  --mint-dark: #046F88;

  font-size: 16px;
}

.nottrueanymore {
  text-decoration: line-through;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(0, -1px, 0);
  }

  20%, 80% {
    transform: translate3d(0, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -4px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 4px, 0);
  }
}

#cookiebar {
  position: absolute;
  top: 0;
  background: var(--orange);
  color: var(--dark-purple);
}

hr {
  display: none;
}

b {
  font-weight: bold;
}

a:link, a:visited {
  color: var(--orange);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;

  font-family: Asap, sans-serif;
  color: $almost-black-purple;

  background: var(--beige);
  margin: 0;
  display: grid;
  grid-template-columns: 12px auto 12px;

  grid-template-areas:
  "header header header"
  "stage stage stage"
  "quote quote quote"
  ". leistungen ."
  "quote3 quote3 quote3"
  ". methoden ."
  "meinung meinung meinung"
  "kontakt kontakt kontakt"
  "footer footer footer";

  > div:nth-of-type(1) {
    grid-area: headerright;
    background: var(--dark-purple);
  }

  > div:nth-of-type(2) {
    grid-area: footerleft;
    background: #270042;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 40px 50px 70fr 50px 40px auto;

    grid-template-areas:
    "header header header header header"
    ". stage stage stage ."
    "quote quote quote quote quote"
    ". leistungen leistungen leistungen ."
    "quote3 quote3 quote3 quote3 quote3"
    ". . methoden . ."
    "meinung meinung meinung meinung meinung"
    "quote2 quote2 quote2 quote2 quote2 "
    ". kontakt kontakt kontakt ."
    "footer footer footer footer footer";
  }
}

body > p:first-of-type {
  grid-area: quote;
  background: $almost-black-purple;

  span {
    display: none;
  }

  @include xl {
    margin: 0;

    span {
      display: inline;
    }
  }

  a:link, a:visited {
    background-color: var(--orange);
    color: white;
  }
}

body > p:nth-of-type(2) {
  grid-area: quote3;
  background: $darkest-green;

  @include xl {
    background: $almost-black-purple;
    margin: 0;
  }
}

body > p:last-of-type {
  background: $darkest-green;
  grid-area: quote2;
  display: none;
  margin-top: 5rem;

  @include xl {
    display: block;
  }
}

body > p {
  color: white;
  display: block;
  text-align: center;
  padding: 1em;
  font-size: .9rem;

  @include xl {
    font-size: 1.1rem;
  }
}

body > header {

  a:link, a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: var(--orange);
  }

  color: white;

  grid-area: header;

  font-size: 18px;

  .flip-content {

    display: flex;
    align-items: start;
    row-gap: 10px;

    align-items: center;
    column-gap: 10px;
    width: 100%;

    &.cookiebar {
      display: flex;
      color: var(--dark-dark-purple);
      padding: .5rem;
      align-items: center;
      flex-wrap: wrap;
      width: auto;
      align-items: center;
      height: 100%;
      box-sizing: border-box;



      @include xl {
        padding: 0 1rem;
        flex-wrap: initial;
      }

    img {
      display: none;

      @include xl {
        display: unset;
      }
    }

    h2 {
      font-size: 24px;

      @include xl {
        font-size: 39px;
      }
    }

    p {
      display: none;
      @include xl {
        font-size: 24px;
        display: unset;
      }
    }

    h2, p {
      margin: 0;
    }

    header {
      flex-grow: 2;
      @include xl {
        width: unset;
      }
    }

    button {
      border-radius: 12px;
      font-size: 12px;
      width: 48px;
      height: 33px;
      color: white;
      background: transparent;
      border: 3px solid white;
      font-size: 15px;
      padding:0;

      @include xl {
        font-size: 18px;
        width: 60px;
        height: 48px;
        margin-right: 1rem;
      }

      &:focus, &:active {
        outline: none;
        background: var(--dark-purple);
      }

      &:active {
        box-shadow: inset 3px 3px 3px 3px #000;
        transform: scale(0.9);
      }

      &:hover {
        border-color: var(--dark-purple);
        cursor: pointer;
      }

      &[name=cookies-ok] {
        color: var(--dark-purple);
        background: white;

        &:focus, &:active {
          outline: none;
          background: var(--dark-purple);
          color: white;
        }

        &:active {
          box-shadow: inset 3px 3px 3px 3px #000;
          transform: scale(0.9);
        }
      }
    }
    }
  }

  @include xl {
    .flip-content {
      display: grid;

      grid-template-columns: 40px auto 400px;

      grid-template-areas:
      ". logo claim";
      font-size: 26px;

      height: 100px;
      align-items: center;

      &.cookiebar {

      }
    }
  }

  .flank-content {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3vw;
  }

  // background: var(--dark-purple);

  .cookie-icon {
    width: 47px;
    height: 47px;
    margin-right: 1rem;
    margin-bottom: .5rem;

    @include xl {
      width: 80px;
      height: 80px;
    }
  }

  img {
    grid-area: logo;
  }

  .front p {
    font-size: 0.75rem;
    grid-area: claim;
    text-align: right;
    margin-left: auto;

    @include xl {
      font-size: 26px;
    }

  }
}

body > footer {
  grid-area: footer;
  background: #270042;
  color: var(--beige);
  padding: 2rem 12px;

  h2 {
    display: none;
  }

  nav {
    text-align: right;

    @include xl {
      margin-top: 3rem;
    }
  }

  ul {
    display: inline-flex;
    list-style-type: none;
  }

  li {
    margin-left: 1rem;
  }

  a:link, a:visited {
    color: var(--beige);
  }

  svg {
    display:none;

    @include xl {
      display: block;
    }
  }
}

.mklogo {
  fill: white;

  width: 141px;
  height: 25px;
  margin-top: -8px;

  @include xl {
    width: 252px;
    height: 76px;
    margin-top: -9px;
  }
}

#leistungen {
  grid-area: leistungen;
  margin-bottom: 5rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  h2 {
    font-weight: normal;
    font-size: 40px;
    margin: 5rem 0 1em 0;
    text-align: center;
  }

  section {
    background-color: var(--dark-purple);
    border-radius: 6px;

    &:hover {
      // background-color: var(--orange);

      ul a:link {
        color: var(--beige);
      }

      ul a:visited {
        color: #e2d6ca;
      }

      ul a:hover {
        color: white;
      }
    }

    position: relative;

    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;

    justify-content: space-between;
    align-items: flex-start;

    ul {
      list-style: none;
      color: #D1BED6;
      padding: 0;
      margin: 0;
      font-weight: 700;
      font-size: 1rem;

      li {
        margin-bottom: .3em;
        font-weight: 400;
      }
    }

    a:not(.main-link) {
      position: relative;
      z-index: 1;
    }

    .main-link::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    a:link {
      color: #D1BED6;
    }

    a:visited {
      color: #af86bb;
      opacity: .8;
    }

    a:hover {
      color: white;

      h3 {
        color: white;
      }
    }

    h3, > a {
      color: var(--beige);
      font-size: 42;
      font-weight: normal;
      align-self: end;
      margin: 0;
      display: block;
      margin-top: 1.4rem;
    }
  }

  @include xl {
    > div {
      gap: 6%;
      flex-direction: row;
    }

    section {
      font-size: 60px;

      padding: 19px;
      width: 27%;

      ul {
        font-size: 1.3rem;
      }
    }
  }
}

#projekte {
  grid-area: methoden;
  margin-bottom: 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:target {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
  }

  h2 {
    font-weight: normal;
    font-size: 40px;
    margin: 5rem 0 1em 0;
    text-align: center;
  }

  section {
    min-width: 50vw;
    max-width: 1000px;
    background: white;
    margin-bottom: 20px;
    display: flex;
    transition-property: transform, box-shadow;
    transition-duration: 0.5s;
    box-shadow: 0px 0px 1px 0 #7B2A00;

    &:hover {
      box-shadow: 0px 12px 12px -12px var(--orange);
      transform: translate(0px, -12px);
      // cursor: pointer;
    }

    header {
      padding: 1em;
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;

      h3 {
        font-size: 30px;
        font-weight: normal;
        margin: .2em 0;
        margin-block-start: .2em;
        margin-block-end: .2em;

        @include xl {
          font-size: 36px;
        }
      }

      p {
        padding: 0;
        margin: 0;
        color: var(--mint-dark);
        font-weight: bold;
      }
    }

    footer {
      display: block;
      text-align: right;
      padding: 1em;
      align-self: flex-end;
    }
  }
}

#meinung {
  grid-area: meinung;
  background-color: #4C90A0;
  color: white;
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    text-align: center;
    margin: 0 0 1.8em;
    font-size: 40px;
    font-weight: normal;
  }

  blockquote {
    font-style: italic;
    max-width: 75vw;
    line-height: 1.6em;

    footer {
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  }

  @include xl {
    padding: 5rem;

    blockquote {
      max-width: 50vw;
    }
  }
}

#kontakt {
  grid-area: kontakt;
  margin-bottom: 5rem;
  margin-top: 5rem;

  &:target {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
  }

  h2 {
    font-weight: normal;
    font-size: 40px;
    margin: 0 0 1em 0;
    text-align: center;
  }

  section {
    background: white;
    margin-bottom: 20px;
    display: flex;
    transition-property: transform, box-shadow;
    transition-duration: 0.5s;
    box-shadow: 0px 0px 1px 0 #7B2A00;

    &:hover {
      box-shadow: 0px 12px 12px -12px var(--orange);
      transform: translate(0px, -12px);
      cursor: pointer;
    }

    header {
      padding: 1em;
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;

      h3 {
        font-size: 30px;
        font-weight: normal;
        margin: .2em 0;
        margin-block-start: .2em;
        margin-block-end: .2em;

        @include xl {
          font-size: 36px;
        }
      }

      p {
        padding: 0;
        margin: 0;
        color: $darker-purple;
        font-weight: bold;
      }
    }

    footer {
      display: block;
      text-align: right;
      padding: 1em;
      align-self: flex-end;
    }
  }
}

dialog {
  padding: 0;

  .dialog-content {
    margin: 1rem;
  }

  h2 {
    margin-top: 0;

    span {
      color: var(--green);
    }
  }

  input[type=email] {
    width: 30vw;
  }
}

@import "stage";

.kindle {
  @media screen {
    display: none;
  }

  @media amzn-mobi,
  amzn-kf8
  and (device-height:1024px)
  and (device-width:  758px),
  amzn-kf8
  and (device-height:758px)
  and (device-width:1024px),
  amzn-kf8
  and (device-height: 800px)
  and (device-width: 600px),
  amzn-kf8
  and (device-height: 600px)
  and (device-width: 800px),
  amzn-kf8
  and (device-height: 1448px)
  and (device-width: 1072px),
  amzn-kf8
  and (device-height: 1072px)
  and (device-width: 1448px) {
    display: block;
  }
}

@import "wordcloud";
@import "contact-form";
