@import "responsive";

@import "variables";

#stage {
  grid-area: stage;

  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  grid-template-areas:
  "header header"
  "button photo"
  ". photo";

  overflow: hidden;
  margin-top: 24px;

  @include xl {
    grid-template-columns: 6fr 1fr 188px;
    grid-template-rows: 1fr 2fr 2fr 10px;
    grid-gap: 26px;

    ul {
      display: flex;
      list-style: none;
      justify-content: space-evenly;
      padding: 0;
    }
  }

  > section {
    grid-column: 1/span 5;
    grid-row: 1/span 3;
    background: white;

    padding: 0 12px;

    @include xl {
      grid-column: 1 / span 2;
      grid-row: 1 / span 3;
      padding: 3rem 6rem;
    }

    a {
      padding: 0.8em;
      border: 3px solid var(--orange);
      border-radius: 18px;
      width: 122px;
      display: block;
      text-align: center;

      @include xl {
        width: 180px;
      }

    }

    a:link, a:visited {
      color: var(--orange);
      text-decoration: none;
      transition-property: transform, box-shadow;
      transition-duration: 0.5s;
    }

    a:hover {
      background: var(--orange);
      color: white;
      border-color: var(--dark-purple);
      transform: translate(0px, -3px);
      box-shadow: 0px 3px 3px -3px var(--orange);
    }

    a:link.coaching, a:visited.coaching {
      background: var(--orange);
      color: white;
    }
  }

  #photo {
    grid-column: 4;
    grid-row: 3/span 2;
    align-self: self-end;

    @include xl {
      grid-column: 2 / span 2;
      grid-row: 2 / span 3;
      align-self: end;
    }
  }

  p {
    font-size: 14px;
    margin: 0;
    color: var(--dark-dark-purple);

    @include xl {
      font-size: 21px;
    }
  }

  ul {
    flex-direction: column;
    list-style: none;
    padding: 0;
    color: var(--dark-dark-purple);
    font-size: 24px;
    line-height: 1.2;
    display: none;

    margin-block-start: 4rem;

    @include xl {
      justify-content: center;
      padding: 0;
      display: flex;
    }

  }

  li {
    margin-bottom: 20px;

    @include xl {
      margin: 0 1rem .25em 0;
    }
  }

  nav {
    font-size: 14px;
    margin-block-start: 4rem;

    span {
      display: none;

      @include xl {
        display: inline;
      }

    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      color: var(--green);
      font-size: 21px;
  
      margin-block-start: 6rem;
  
      @include xl {
        justify-content: start;
        padding: 0;
        flex-direction: row;
      }
  
    }
  
    li {
      margin-bottom: 20px;
  
      @include xl {
        margin: 0 1rem 0 0;
      }
    }

    @include xl {
      font-size: 18px;
    }
  }

  img {
    display: block;
    width: 171px;
    height: 151px;

    @include xl {
      width: 318px;
      height: 281px;
    }
  }
}

h1 {
  font-size: 32px;
  color: var(--dark-dark-purple);
  margin:0;
  font-weight: bold;

  @include xl {
    font-size: 78px;
  }
}

#contact-info {
  grid-column: 1 / colspan 5;
  grid-row: 4;

  color: white;
  background: $almost-black-purple;

  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;

  .name, .address {
    display: none;
  }

  @include xl {
    grid-column: 3;
    grid-row: 2;
    color: var(--dark-purple);
    background: transparent;
    display: initial;

    .name, .address {
      display: block;
    }
  }

  .name {
    font-weight: bold;
    font-size: 22px;
  }

  .title {
    span {
      display: block;
    }
    display: block;
    margin: 0;
    @include xl {
      margin: 1em 0;
    }
  }

  .freelance {
    position: absolute;
    top: -33px;
    left: 12px;
    color: $purple;

    @include xl {
      position: initial;
      top: initial;
      left: initial;
    }


    font-weight: bold;
    margin: .7em 0 .4em 0;
  }

  .tel {
    display: block;
    width: 100%;
    text-align: right;

    a:link {
      color: white;
      font-weight: bold;
      font-size: 18px;
    }

    @include xl {
      width: initial;
      text-align: initial;
      margin-top: 1em;

      a:link {
        color: var(--orange);
        font-size: 1rem;
      }
    }
  }
}
