
// COLORS

// purple
$purple: #5B0993;
$darker-purple: #470674;
$darkest-purple: #320353;
$almost-black-purple: #270042;

// green
$darkest-green: #013E4C;
