


//
// CSS FLIP BOX
// --------------------------------------------------


// vars size
$box-height: 100px;
$perspective: 50px;

// vars colors
$bg-color-front: #410B66;
$bg-color-back: #FD722B;;
$bg-color-flank: white;

$bg-color-front-alternative: #931e83;
$bg-color-back-alternative: lighten($bg-color-front-alternative, 10%);
$bg-color-flank-alternative: darken($bg-color-front-alternative, 10%);

$dividedPerspective: calc($perspective / 2);


// 2 extend

.full-size {
  width: 100%;
  height: 100%;
}

.slice {
  display: block;
  overflow: hidden;
  position: absolute;
}




// flip box

.flip-box {

  --box-height: 67px;
  --perspective: 50px;
  
  @include xl {
    --box-height: 100px;
  }
  
  --divided-perspective: calc(var(--perspective) / 2);

  @keyframes slideInFromLeft {

    0% {
      transform: translateZ(-var(--divided-perspective)) rotateX(0deg);
    }
    50% {
      transform: translateZ(-var(--divided-perspective)) rotateX(180deg);
    }
    100% {
      transform: translateZ(-var(--divided-perspective)) rotateX(0deg);
    }
  }


  float: left;
  display: block;
  position: relative;
  z-index: 2;
  height: var(--box-height);
  perspective: 400px;

  .object {
    @extend .full-size;
    position: absolute;
    transition-property: transform;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transform-style: preserve-3d;
    transform: translateZ(calc(0px - var(--divided-perspective)));

    &.cookie-bar {
      transition-property: transform;
      transition-duration: 2.5s;
      transition-timing-function: ease-in-out;
      transform: translateZ(calc(0px - var(--divided-perspective))) rotateY(180deg);
    }


  }

  .front {
    @extend .full-size;
    @extend .slice;
    z-index: 1;
    background-color: $bg-color-front;
    transform: rotateY(0deg) translateZ(var(--divided-perspective));
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include xl {
      padding: 0 40px;
      display: block;
    }
  }

  .back {
    @extend .full-size;
    @extend .slice;
    z-index: 0;
    background-color: $bg-color-back;
    transform: rotateY(180deg) translateZ(var(--divided-perspective));
  }

  .flank {
    @extend .slice;
    width: $perspective;
    height: var(--box-height);
    left: 250px;
    background-color: $bg-color-flank;
    transform: rotateY(-90deg) translateZ(50vw);
    color: #410B66;

    display: flex;
    align-items: center;
    justify-content: center;
  }


  // flip up
  &.flip-up {

    .object {

      // animation: 10s ease-out 0s 1 slideInFromLeft;
      // animation-iteration-count: infinite;

      &.cookie-bar {
        transform: translateZ(calc(0px - var(--divided-perspective))) rotateX(180.5deg);
      }

    }

    .back {
      transform: rotateX(180deg) translateZ(var(--divided-perspective));
    }

    .flank {
      width: 100%;
      height: $perspective;
      left: 0;
      top: calc((var(--box-height) - var(--perspective)) / 2 );
      transform: rotateX(-89deg) translateZ(calc(var(--box-height) / 2));
    }

  }


  // colors (just if you need them)

  &.alternative {

    .front {
      background-color: $bg-color-front-alternative;
    }

    .back {
      background-color: $bg-color-back-alternative;
    }

    .flank {
      background-color: $bg-color-flank-alternative;
    }

  }

}


// FALLBACK
// fallback for older browsers. needs modernizr which sets class 'no-csstransforms3d' to html-tag

.no-csstransforms3d .flip-box {

  .object {
    position: relative;
    overflow: hidden;
    transform-style: none;

    .back {
      top: 100%;
      z-index: 0;
      transition-property: top;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }

    &:hover .back {
      top: 0;
      z-index: 1;
    }

  }

  .flank {
    display: none;
  }

}


/* Internet Explorer 10 only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .object {
    position: relative;
    overflow: hidden;
    transform: none !important;

    .back {
      top: 100%;
      z-index: 0;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

    }

    &:hover .back {
      top: 0;
      z-index: 1;
      transform: rotateY(0deg) translateZ(0);
    }

  }

}
